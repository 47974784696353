import { userStore } from '@/auth/state'
import { userGroupsCollection } from '../../firebaseConfig'
import GroupConfigApi, { Group, GroupConfig } from './api'

const groupId = userStore.state.currentUser.group
class GroupConfigFirebaseApi implements GroupConfigApi {
  async getGroupWithConfig (): Promise<Group> {
    const doc = await userGroupsCollection.doc(groupId).get()
    if (!doc.exists) {
      return await Promise.reject(new Error('Unable to find group with id: ' + groupId))
    }

    const data = doc.data() as any
    if (data.config === undefined) {
      data.config = {}
    }

    return data as Group
  }

  async updateGroupConfig (groupConfig: GroupConfig): Promise<void> {
    return await userGroupsCollection.doc(groupId).update({
      config: groupConfig
    })
  }
}

const groupConfigApi = new GroupConfigFirebaseApi()
export { groupConfigApi }
