



































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import ActionIcon from '@/shared/components/ActionIcon.vue'
import ActionIconWithDialog from '@/shared/components/ActionIconWithDialog.vue'

import RecipeApi, { SearchScope } from '@/recipe/api/api'
import { recipeFirebaseApi } from '@/recipe/api/firebaseApi'
import { SHOPPINGLIST_ADD_RECIPE } from '../../shoppinglist/events'
import { RecipeSimple, RecipeSimpleWithMeta } from '../model/recipe'
import { isWritable as isRecipeWritable } from '../authorization/recipeAuthorization'
import { DataTableHeader } from 'vuetify'

@Component({
  components: { ActionIcon, ActionIconWithDialog },
  methods: { isRecipeWritable }
})
export default class Recipes extends Vue {
  loading = false
  allLoaded = false
  timeoutId?: number

  search = ''
  isSearchAll = false
  pageSize = 10
  headers: DataTableHeader[] = [
    {
      text: this.$root.$tc('table.name'),
      value: 'name',
      align: 'start',
      sortable: false
    },
    {
      text: this.$root.$tc('rcp.description'),
      value: 'description',
      sortable: false,
    },
    {
      text: this.$root.$tc('rcp.serving_size'),
      value: 'servingSize',
      sortable: false,
      width: '120px' 
    },
    { 
      text: this.$root.$tc('table.actions'), 
      value: 'actions', 
      sortable: false, 
      width: '120px' 
    }
  ]

  items: RecipeSimpleWithMeta[] = []
  allRecipeCounter = 0

  recipeApi: RecipeApi = recipeFirebaseApi

  async mounted (): Promise<void> {
    this.initializeFromUrl()

    await this.loadRecipes()
    await this.loadRecipeCounter()
    this.loadRecipeCounterDelayed()
  }

  @Watch('search')
  searchChangedDebounced (): void {
    clearTimeout(this.timeoutId)

    this.timeoutId = setTimeout(async () => {
      await this.loadRecipes()
    }, 500)
  }

  get searchScope (): SearchScope {
    return this.isSearchAll ? SearchScope.PUBLIC : SearchScope.GROUP
  }

  async loadRecipes (): Promise<void> {
    this.allLoaded = false

    this.loading = true
    this.items = await this.recipeApi.search(this.search, this.searchScope, {
      pageSize: this.pageSize,
      last: undefined,
    })
    this.loading = false

    await this.writeQueryParams()
  }

  async loadMore (): Promise<void> {
    if (this.items.length == 0) {
      return
    }

    this.loading = true
    const moreItems = await this.recipeApi.search(this.search, this.searchScope, {
      pageSize: this.pageSize,
      last: this.items[this.items.length - 1]
    })

    if (moreItems.length == 0) {
      this.allLoaded = true
    }

    this.items.push(...moreItems)
    this.loading = false
  }

  async onInfo (item: RecipeSimple): Promise<void> {
    await this.$router.push(`/recipes/${item.id}`)
  }

  async loadRecipeCounter (): Promise<void> {
    await this.recipeApi.counter().then(counter => (this.allRecipeCounter = counter))
  }

  loadRecipeCounterDelayed (): void {
    setTimeout(async () => {
      await this.loadRecipeCounter()
    }, 4000)
  }

  async deleteItem (item: RecipeSimple): Promise<void> {
    await this.recipeApi.delete(item.id)
    this.items = this.items.filter(x => x.id != item.id)
    this.allRecipeCounter--
  }

  async editItem (item: RecipeSimple): Promise<void> {
    await this.$router.push(`/recipes/${item.id}/edit`)
  }

  async copyItem (item: RecipeSimple): Promise<void> {
    await this.$router.push(`/recipes/${item.id}/copy`)
  }

  addToShoppinglist (item: RecipeSimple): void {
    const event = new CustomEvent<RecipeSimple>(SHOPPINGLIST_ADD_RECIPE, {
      detail: item
    })
    window.dispatchEvent(event)
  }

  async writeQueryParams (): Promise<void> {
    const noChanges = this.search === this.getQueryParamFromUrl('search') 
      && this.isSearchAll === this.getIsSearchAllFromUrl() 
    if (noChanges) {
      return
    }

    const query: {
      search?: string
      isSearchAll?: string
    } = {}
    if (this.search !== '') {
      query.search = this.search 
    }
    if (this.isSearchAll) {
      query.isSearchAll = 'true'
    }

    await this.$router.replace({ query })
  }

  initializeFromUrl (): void {
    this.search = this.getQueryParamFromUrl('search')
    this.isSearchAll = this.getIsSearchAllFromUrl()
  }

  getIsSearchAllFromUrl (): boolean {
    return this.getQueryParamFromUrl('isSearchAll') === 'true'
  }
  
  getQueryParamFromUrl (key: string): string {
    const rawParam = this.$route.query[key]
    if (Array.isArray(rawParam)) {
      return rawParam[0] ?? ''
    } 
    return rawParam ?? ''
  }
}
