

























































































































































import Vue from 'vue'
import Component from 'vue-class-component'

import marked from 'marked'

import { Recipe, Ingredient } from '@/recipe/model/recipe'
import { Allergen, NutrimentsPer100g, VictualUnit } from '@/victual/model/victual'
import { Prop } from 'vue-property-decorator'
import { groupConfigApi } from '@/config/api/firebaseApi'

@Component({})
export default class RecipePrintPreview extends Vue {
  @Prop()
  recipe!: Recipe

  printHeader = ''

  async mounted (): Promise<void> {
    await this.loadPrintHeader()
  }

  get nutritionData (): NutrimentsPer100g {
    let energyInKcal = 0,
      fats = 0,
      carbs = 0,
      proteins = 0

    for (const ingredientGroup of this.recipe.ingredientGroups) {
      for (const ingredient of ingredientGroup.ingredients) {
        const nutriments = ingredient.victual.nutriments
        energyInKcal += (nutriments.energyInKcal * ingredient.amountInGrams) / 100
        fats += (nutriments.fats * ingredient.amountInGrams) / 100
        carbs += (nutriments.carbs * ingredient.amountInGrams) / 100
        proteins += (nutriments.proteins * ingredient.amountInGrams) / 100
      }
    }

    return {
      energyInKcal: energyInKcal / this.recipe.servingSize,
      fats: fats / this.recipe.servingSize,
      carbs: carbs / this.recipe.servingSize,
      proteins: proteins / this.recipe.servingSize
    }
  }

  get allergens (): Allergen[] {
    let allergens: Allergen[] = []
    for (const ingredientGroup of this.recipe.ingredientGroups) {
      for (const ingredient of ingredientGroup.ingredients) {
        allergens = allergens.concat(ingredient.victual.allergens)
      }
    }
    return allergens
  }

  get printableAllergens (): string[] {
    const allergenStrings = Object.values(Allergen)
      .filter(allergen => this.allergens.includes(allergen))
      .map(allergen => {
        return this.$t(`vic.allerg.${allergen}`) as string
      })

    return allergenStrings.sort((x, y) => x.localeCompare(y))
  }

  getSelectedUnitAmount (ingredient: Ingredient): string {
    const shownUnit = this.getSelectedUnit(ingredient)
    if (shownUnit === null) {
      return ''
    }
    const unitAmount = ingredient.amountInGrams / shownUnit.oneUnitEqualsGrams
    if (unitAmount < 1) {
      return `< 1 ${shownUnit.name}` 
    }

    return `${unitAmount} ${shownUnit.name}`
  }

  getSelectedUnit (ingredient: Ingredient): VictualUnit | null {
    const matchingUnits = ingredient.victual.units.filter(
      x => x.name === ingredient.displayedUnit
    )
    if (matchingUnits.length !== 1) {
      return null
    }
    return matchingUnits[0]
  }

  getPriceForAmount (ingredient: Ingredient): number {
    const cost =
      (ingredient.amountInGrams * ingredient.victual.pricePer100g) / 100
    return parseFloat(cost.toFixed(2))
  }

  getPriceForRecipe (): number {
    let sum = 0
    this.recipe.ingredientGroups.forEach(ingredientGroup => {
      ingredientGroup.ingredients.forEach(ingredient => {
        sum += this.getPriceForAmount(ingredient)
      })
    })
    return parseFloat(sum.toFixed(2))
  }

  getPricePerPerson (): number {
    const total = this.getPriceForRecipe()
    const costPerPerson = total / this.recipe.servingSize
    return parseFloat(costPerPerson.toFixed(2))
  }

  get compiledDirections (): string {
    return marked(this.recipe.directions)
  }

  async loadPrintHeader (): Promise<void> {
    const group= await groupConfigApi.getGroupWithConfig()
    this.printHeader = group.config.printHeader ?? ''
  }
}
