






















































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, PropSync, Watch } from 'vue-property-decorator'

import draggable from 'vuedraggable'
import IngredientAmountChooser from './IngredientAmountChooser.vue'
import MarkdownEditor from '@/shared/components/MarkdownEditor.vue'

import { Recipe, Ingredient, IngredientGroup } from '../model/recipe'
import RecipeApi from '@/recipe/api/api'
import { recipeFirebaseApi } from '@/recipe/api/firebaseApi'
import VictualApi from '@/victual/api/api'
import { victualFirebaseApi } from '@/victual/api/firebaseApi'
import VictualSearchForm from '@/victual/components/VictualSearchForm.vue'
import { VictualSearch } from '../../victual/components/victualSearch'
import Victual from '@/victual/model/victual'

@Component({
  components: {
    draggable,
    IngredientAmountChooser,
    VictualSearchForm,
    MarkdownEditor
  }
})
export default class RecipeForm extends Vue {
  descriptionPanel = [0, 1]
  scaleIngredients = true
  valid = false
  loading = false

  availableIngredients: Ingredient[] = []

  ingredientSearch: VictualSearch = {
    searchTerm: '',
    categories: []
  }

  ingredientPageSize = 5
  allIngredientsLoaded = false
  timeoutId?: number

  recipelApi: RecipeApi = recipeFirebaseApi
  victualApi: VictualApi = victualFirebaseApi

  availableIngredientsDraggableOptions = {
    group: {
      name: 'vic',
      pull: 'clone',
      put: false
    },
    sort: false
  }

  async mounted (): Promise<void> {
    await this.loadIngredients()
  }

  @PropSync('value')
  recipe!: Recipe

  @Emit('value')
  emitValue (): Recipe {
    return this.recipe
  }

  @Watch('recipe')
  recipeChanged (): void {
    this.emitValue()
  }

  handleClone (item: Ingredient): Ingredient {
    return Object.assign({}, item)
  }

  addIngredient (item: Ingredient): void {
    this.recipe.ingredientGroups[0].ingredients.push(this.handleClone(item))
  }

  removeIngredient (ingredientGroup: IngredientGroup, itemIndex: number): void {
    ingredientGroup.ingredients.splice(itemIndex, 1)
  }

  addIngredientGroup (): void {
    this.recipe.ingredientGroups.push({
      name: this.$root.$t('new') as string,
      ingredients: []
    })
  }

  deleteIngredientGroup (item: IngredientGroup): void {
    this.recipe.ingredientGroups = this.recipe.ingredientGroups.filter(
      x => x !== item
    )
  }

  removeAllFromSelected (): void {
    this.recipe.ingredientGroups[0].ingredients = []
  }

  @Watch('ingredientSearch', { deep: true })
  async searchChangedDebounced (): Promise<void> {
    clearTimeout(this.timeoutId)

    this.timeoutId = setTimeout(async () => {
      await this.loadIngredients()
    }, 500)
  }

  async loadIngredients (): Promise<void> {
    this.allIngredientsLoaded = false

    this.loading = true

    this.availableIngredients = await this.doLoadIngredients(
      this.ingredientSearch.searchTerm,
      this.ingredientPageSize,
      undefined
    )
    this.loading = false
  }

  async loadMore (): Promise<void> {
    if (this.availableIngredients.length == 0) {
      return
    }

    this.loading = true
    const lastLoadedIngredient = this.availableIngredients[
      this.availableIngredients.length - 1
    ]

    const moreIngredients = await this.doLoadIngredients(
      this.ingredientSearch.searchTerm,
      this.ingredientPageSize,
      lastLoadedIngredient.victual
    )

    if (moreIngredients.length == 0) {
      this.allIngredientsLoaded = true
    }

    this.availableIngredients.push(...moreIngredients)
    this.loading = false
  }

  async doLoadIngredients (
    searchStr: string,
    pageSize: number,
    last?: Victual
  ): Promise<Ingredient[]> {
    const searchResults = await this.victualApi.search(
      searchStr,
      this.ingredientSearch.categories,
      {
        pageSize: pageSize,
        last: last
      }
    )

    const moreIngredients: Ingredient[] = searchResults.map(v => ({
      victual: v,
      amountInGrams: v.defaultAmountInGrams,
      displayedUnit: undefined
    }))

    return moreIngredients
  }

  @Watch('recipe.servingSize')
  onServiceSizeChanged (val: number, oldVal: number): void {
    if (!this.scaleIngredients) {
      return
    }

    this.recipe.ingredientGroups.forEach(recipeGroup => {
      recipeGroup.ingredients.forEach(ingredient => {
        if (val >= 0.001 && oldVal >= 0.001) {
          ingredient.amountInGrams *= val / oldVal
        }
      })
    })
  }

  onDirectionsChanged (directions: string): void {
    this.recipe.directions = directions
  }
}
