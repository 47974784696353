




































































































import Vue from 'vue'
import Component from 'vue-class-component'

import RecipePrintPreview from '@/recipe/components/RecipePrintPreview.vue'
import ActionIcon from '@/shared/components/ActionIcon.vue'
import ActionIconWithDialog from '@/shared/components/ActionIconWithDialog.vue'

import { RecipeSimple, RecipeWithMeta } from '@/recipe/model/recipe'
import RecipeApi from '@/recipe/api/api'
import { recipeFirebaseApi } from '@/recipe/api/firebaseApi'
import { SHOPPINGLIST_ADD_RECIPE } from '@/shoppinglist/events'
import { isWritable } from '../authorization/recipeAuthorization'
import { userStore } from '@/auth/state'

@Component({
  components: { RecipePrintPreview, ActionIcon, ActionIconWithDialog }
})
export default class RecipeInfo extends Vue {
  recipe: RecipeWithMeta = {
    id: '0',
    name: '',
    description: '',
    directions: '',
    servingSize: 1,
    ingredientGroups: [],
    meta: {
      createdAt: new Date(),
      lastModifiedAt: new Date(),
      createdByUserId: 'unkown',
      lastModifiedByUserId: 'unkown',
      ownedByGroupId: 'unkown'
    }
  }

  groupName: string = ''
  isCurrentUserPartOfGroup = false

  isEditable = false

  loading = false

  recipeApi: RecipeApi = recipeFirebaseApi

  async mounted (): Promise<void> {
    const id = this.$route.params.id

    this.loading = true
    this.recipe = await this.recipeApi.get(id)
    this.groupName = userStore.groupName(this.recipe.meta.ownedByGroupId)
    this.isCurrentUserPartOfGroup = userStore.state.currentUser?.group === this.recipe.meta.ownedByGroupId
    this.isEditable = isWritable(this.recipe.meta)

    this.loading = false
  }



  async onBack (): Promise<void> {
    await this.$router.go(-1)
  }

  async onEdit (): Promise<void> {
    await this.$router.push(`/recipes/${this.recipe.id}/edit`)
  }

  onPrint (): void {
    window.print()
  }

  async onDelete (): Promise<void> {
    await this.recipeApi.delete(this.recipe.id)
    await this.$router.push('/recipes/')
  }

  async onCopy (): Promise<void> {
    await this.$router.push(`/recipes/${this.recipe.id}/copy`)
  }

  onToShoppinglist (): void {
    const event = new CustomEvent<RecipeSimple>(SHOPPINGLIST_ADD_RECIPE, {
      detail: this.recipe
    })
    window.dispatchEvent(event)
  }
}
