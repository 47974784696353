














































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class ActionWithDialog extends Vue {
  dialog = false

  @Prop(String)
  icon!: string

  @Prop(String)
  tooltip!: string

  @Prop(String)
  title!: string

  @Prop(String)
  msg!: string

  @Prop(String)
  yesLabel!: string

  @Prop({ default: false })
  disabled!: boolean

  onAgree (event: Event): void {
    this.dialog = false
    this.$emit('click', event)
  }
}
