


























































































import marked from 'marked'
import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'

@Component({})
export default class MarkdownEditor extends Vue {
  tab = null
  @PropSync('value')
  input!: string

  get rawData (): string {
    return this.input
  }

  set rawData (value: string) {
    this.$emit('value', value)
  }

  get compiledData (): string {
    return marked(this.rawData)
  }

  get showPreview (): boolean {
    return this.tab == 1
  }

  get showEditor (): boolean {
    return this.tab == 0
  }
}
