


















import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, PropSync, Watch } from 'vue-property-decorator'
import { Ingredient } from '../model/recipe'
import { VictualUnit } from '@/victual/model/victual'

const DEFAULT_FACTOR = 1

@Component({})
export default class IngredientAmountChooser extends Vue {
  @PropSync('value')
  ingredient!: Ingredient

  get amountInUnit (): number {
    if (this.selectedUnit === null) {
      return this.ingredient.amountInGrams
    }

    return this.ingredient.amountInGrams / this.selectedUnit.oneUnitEqualsGrams
  }

  set amountInUnit (value: number) {
    let factor = this.selectedUnit?.oneUnitEqualsGrams
    if (factor === undefined) {
      factor = DEFAULT_FACTOR
    }
    this.ingredient.amountInGrams = value * factor
  }

  get selectedUnitDisplayName (): string {
    const ingredientUnit = this.ingredient.displayedUnit
    if (ingredientUnit === undefined) {
      return this.defaultUnitName
    }

    return ingredientUnit
  }

  set selectedUnitDisplayName (value: string) {
    const unitIsGrams = value === this.defaultUnitName
    if (unitIsGrams) {
      this.ingredient.displayedUnit = undefined
      return
    }

    const matchingUnits = this.ingredient.victual.units.filter(
      x => x.name === value
    )

    if (matchingUnits.length !== 1) {
      this.ingredient.displayedUnit = undefined
      return
    }

    this.ingredient.displayedUnit = matchingUnits[0].name
  }

  get selectedUnit (): VictualUnit | null {
    const matchingUnits = this.ingredient.victual.units.filter(
      x => x.name === this.ingredient.displayedUnit
    )
    if (matchingUnits.length !== 1) {
      return null
    }
    return matchingUnits[0]
  }

  @Emit('value')
  emitValue (): Ingredient {
    return this.ingredient
  }

  @Watch('ingredient', { deep: true })
  ingredientChanged (): void {
    this.emitValue()
  }

  get availableUnits (): string[] {
    return [
      this.defaultUnitName,
      ...this.ingredient.victual.units.map(x => x.name)
    ]
  }

  get defaultUnitName (): string {
    return this.$root.$t('vic.units.grams') as string
  }
}
