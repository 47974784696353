



















































































import Vue from 'vue'
import Component from 'vue-class-component'

import RecipeForm from '@/recipe/views/RecipeForm.vue'
import RecipePrintPreview from '@/recipe/components/RecipePrintPreview.vue'

import { Recipe } from '@/recipe/model/recipe'
import RecipeApi from '@/recipe/api/api'
import { recipeFirebaseApi } from '@/recipe/api/firebaseApi'
import { isWritable } from '../authorization/recipeAuthorization'

@Component({
  components: {
    RecipeForm,
    RecipePrintPreview
  }
})
export default class RecipeEdit extends Vue {
  loading = false

  recipelApi: RecipeApi = recipeFirebaseApi
  recipe: Recipe = {
    id: '',
    name: '',
    description: '',
    directions: '',
    servingSize: 0,
    ingredientGroups: [
      {
        name: 'General',
        ingredients: []
      }
    ]
  }

  printDialog = false

  async mounted (): Promise<void> {
    this.loading = true

    const id = this.$route.params.id
    const recipeResponse = await this.recipelApi.get(id)
    if (!isWritable(recipeResponse.meta)) {
      await this.onCancel()
      return
    }
    this.recipe = recipeResponse

    this.loading = false
  }

  async onUpdate (): Promise<void> {
    await this.recipelApi.update(this.recipe.id, this.recipe)
    await this.$router.push(`/recipes/${this.recipe.id}`)
  }

  async onCancel (): Promise<void> {
    await this.$router.go(-1)
  }
}
