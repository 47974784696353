














































































import Vue from 'vue'
import Component from 'vue-class-component'

import RecipeForm from '@/recipe/views/RecipeForm.vue'
import RecipePrintPreview from '@/recipe/components/RecipePrintPreview.vue'

import { Recipe } from '@/recipe/model/recipe'
import RecipeApi from '@/recipe/api/api'
import { recipeFirebaseApi } from '@/recipe/api/firebaseApi'

@Component({
  components: {
    RecipeForm,
    RecipePrintPreview
  }
})
export default class RecipeCreate extends Vue {
  recipelApi: RecipeApi = recipeFirebaseApi
  recipe: Recipe = {
    id: '',
    name: '',
    description: '',
    directions: '',
    servingSize: 4,
    ingredientGroups: [
      {
        name: 'General',
        ingredients: []
      }
    ]
  }

  printDialog = false

  async onCreate (): Promise<void> {
    const id = await this.recipelApi.create(this.recipe)
    await this.$router.push(`/recipes/${id}`)
  }

  async onCancel (): Promise<void> {
    await this.$router.go(-1)
  }
}
