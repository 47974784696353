import { render, staticRenderFns } from "./MarkdownEditor.vue?vue&type=template&id=19a1d006&scoped=true&"
import script from "./MarkdownEditor.vue?vue&type=script&lang=ts&"
export * from "./MarkdownEditor.vue?vue&type=script&lang=ts&"
import style0 from "./MarkdownEditor.vue?vue&type=style&index=0&id=19a1d006&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a1d006",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VDivider,VSimpleTable,VTab,VTabItem,VTabs,VTabsItems,VTextarea})
