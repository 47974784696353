



















































import Vue from 'vue'
import Component from 'vue-class-component'

import RecipeForm from '@/recipe/views/RecipeForm.vue'

import { Recipe } from '@/recipe/model/recipe'
import RecipeApi from '@/recipe/api/api'
import { recipeFirebaseApi } from '@/recipe/api/firebaseApi'

@Component({
  components: {
    RecipeForm
  }
})
export default class RecipeCopy extends Vue {
  loading = false

  recipelApi: RecipeApi = recipeFirebaseApi
  recipe: Recipe = {
    id: '',
    name: '',
    description: '',
    directions: '',
    servingSize: 0,
    ingredientGroups: [
      {
        name: 'General',
        ingredients: []
      }
    ]
  }

  async mounted (): Promise<void> {
    this.loading = true

    const id = this.$route.params.id
    this.recipe = await this.recipelApi.get(id)
    this.recipe.name += ' (Copy)'

    this.loading = false
  }

  async onCreate (): Promise<void> {
    const recipeId = await this.recipelApi.create(this.recipe)
    await this.$router.push(`/recipes/${recipeId}`)
  }

  async onCancel (): Promise<void> {
    await this.$router.go(-1)
  }
}
